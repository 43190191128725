// src/components/PublicLayout.js
import PublicHeader from "./PublicHeader";

const PublicLayout = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      <PublicHeader />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">{children}</main>
    </div>
  );
};

export default PublicLayout;
