import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isActive = (path) => location.pathname === path;

  const handleNavClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <header className="fixed top-0 w-full bg-[#0C0D0D] border-b border-gray-800 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link
                to="https://virtualhomestaging.co/"
                className="text-xl font-bold text-white hover:text-gray-200 transition-colors"
              >
                {t("app.components.header.brand")}
              </Link>
              {/* Desktop Navigation */}
              <nav className="hidden md:flex ml-10 space-x-6">
                <Link
                  to="https://virtualhomestaging.co/"
                  className={`${
                    isActive("/")
                      ? "text-white font-semibold"
                      : "text-gray-400 hover:text-white"
                  } transition-colors`}
                >
                  {t("app.components.header.nav.home")}
                </Link>
                <Link
                  to="https://virtualhomestaging.co/#pricing"
                  className={`${
                    isActive("/prices")
                      ? "text-white font-semibold"
                      : "text-gray-400 hover:text-white"
                  } transition-colors`}
                >
                  {t("app.components.header.nav.pricing")}
                </Link>
                {/* <Link
                  to="https://virtualhomestaging.co/blog"
                  className={`${
                    isActive("/blog")
                      ? "text-white font-semibold"
                      : "text-gray-400 hover:text-white"
                  } transition-colors`}
                >
                  {t("app.components.header.nav.blog")}
                </Link> */}
              </nav>
            </div>

            {/* Language selector and mobile menu button */}
            <div className="flex items-center space-x-4">
              <div className="relative inline-block text-left">
                <select
                  onChange={(e) => changeLanguage(e.target.value)}
                  value={i18n.language}
                  className="block appearance-none bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="es">Español</option>
                  <option value="en">English</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>

              {/* Mobile menu button */}
              <button
                onClick={toggleMenu}
                className="md:hidden text-gray-400 hover:text-white focus:outline-none transition-colors"
              >
                {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-[#1A1A1A] border-t border-gray-800">
            <nav className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="https://virtualhomestaging.co/"
                onClick={handleNavClick}
                className={`block px-3 py-2 rounded-lg ${
                  isActive("/")
                    ? "bg-gray-800 text-white"
                    : "text-gray-400 hover:bg-gray-800 hover:text-white"
                } transition-colors`}
              >
                {t("app.components.header.nav.home")}
              </Link>
              <Link
                to="https://virtualhomestaging.co/prices"
                onClick={handleNavClick}
                className={`block px-3 py-2 rounded-lg ${
                  isActive("/prices")
                    ? "bg-gray-800 text-white"
                    : "text-gray-400 hover:bg-gray-800 hover:text-white"
                } transition-colors`}
              >
                {t("app.components.header.nav.pricing")}
              </Link>
              <Link
                to="https://virtualhomestaging.co/blog"
                onClick={handleNavClick}
                className={`block px-3 py-2 rounded-lg ${
                  isActive("/blog")
                    ? "bg-gray-800 text-white"
                    : "text-gray-400 hover:bg-gray-800 hover:text-white"
                } transition-colors`}
              >
                {t("app.components.header.nav.blog")}
              </Link>
            </nav>
          </div>
        )}
      </header>
      <div className="h-[72px]"></div>
    </>
  );
};

export default Header;
