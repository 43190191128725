// src/components/AuthLayout.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../api/supabaseClient";
import Header from "./Header";

const AuthLayout = ({ children, fullWidth = false }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        setUser(user);
      } else {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (loading) {
    return <div>Loading...</div>; // Or a proper loading component
  }

  return (
    <div className={`min-h-screen ${!fullWidth ? "bg-gray-100" : ""}`}>
      <Header user={user} />
      <main
        className={fullWidth ? "" : "max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"}
      >
        {children}
      </main>
    </div>
  );
};
export default AuthLayout;
