// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthLayout from "./components/AuthLayout";
import PublicLayout from "./components/PublicLayout";
import Login from "./pages/Login";
import RequestPasswordReset from "./pages/RequestPasswordReset";
import ResetPassword from "./pages/ResetPassword";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import UploadNewImage from "./pages/UploadNewImage";
import ShowResult from "./pages/ShowResult";
import Processing from "./pages/Processing";
import Results from "./pages/Results";
import PricingSection from "./pages/PricingSection";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import Hotjar from "./common/Hotjar";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Hotjar hjid={5253659} hjsv={6} />
      <Router>
        <Routes>
          <Route
            path="/signup"
            element={
              <PublicLayout>
                <SignUp />
              </PublicLayout>
            }
          />
          <Route
            path="/login"
            element={
              <PublicLayout>
                <Login />
              </PublicLayout>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicLayout>
                <RequestPasswordReset />
              </PublicLayout>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicLayout>
                <ResetPassword />
              </PublicLayout>
            }
          />
          <Route
            path="/"
            element={
              <AuthLayout>
                <Dashboard />
              </AuthLayout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <AuthLayout>
                <Dashboard />
              </AuthLayout>
            }
          />
          <Route
            path="/results"
            element={
              <AuthLayout>
                <Results />
              </AuthLayout>
            }
          />
          <Route
            path="/upload-image"
            element={
              <AuthLayout>
                <UploadNewImage />
              </AuthLayout>
            }
          />
          <Route
            path="/result/:id"
            element={
              <AuthLayout>
                <ShowResult />
              </AuthLayout>
            }
          />
          <Route
            path="/processing/:imageId"
            element={
              <AuthLayout fullWidth>
                <Processing />
              </AuthLayout>
            }
          />
          <Route
            path="/prices"
            element={
              <AuthLayout>
                <PricingSection />
              </AuthLayout>
            }
          />
          {/* Add other routes as needed */}
        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;
