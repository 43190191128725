import React from "react";
import { useTranslation } from "react-i18next";

const EmptyRoomConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>

      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="relative bg-white rounded-lg p-6 max-w-sm w-full mx-auto">
          <h3 className="text-lg font-medium mb-4">
            {t("app.components.EmptyRoomConfirmationModal.title")}
          </h3>

          <p className="text-sm text-gray-600 mb-6">
            {t("app.components.EmptyRoomConfirmationModal.message")}
          </p>
          <p className="text-sm text-gray-600 mb-6">
            {t("app.components.EmptyRoomConfirmationModal.message_2")}
          </p>

          <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
            <button
              onClick={onConfirm}
              className="w-full sm:w-auto px-4 py-2 bg-[#0198FE] text-white rounded hover:bg-[#0182d8] font-medium"
            >
              {t("app.components.EmptyRoomConfirmationModal.confirm")}
            </button>
            <button
              onClick={onClose}
              className="w-full sm:w-auto px-4 py-2 border border-gray-300 rounded hover:bg-gray-50 font-medium"
            >
              {t("app.components.EmptyRoomConfirmationModal.cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyRoomConfirmationModal;
