const RegenerationProgress = ({ progress, status, error }) => {
  return (
    <div className="fixed top-0 left-0 right-0 z-50">
      {/* Progress bar */}
      <div className="h-2 w-full bg-purple-100">
        <div
          className={`h-full transition-all duration-500 ${
            error
              ? "bg-rose-500"
              : progress === 100
              ? "bg-emerald-500"
              : "bg-purple-600"
          }`}
          style={{ width: `${progress}%` }}
        />
      </div>

      {/* Status message */}
      <div className="bg-purple-900 shadow-lg border-b border-purple-700">
        <div className="max-w-7xl mx-auto px-4 py-3 text-sm">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {error ? (
                <span className="text-rose-300 font-medium">
                  Error: {error}
                </span>
              ) : progress === 100 ? (
                <span className="text-emerald-300 font-medium">
                  ¡Regeneración completada con éxito!
                </span>
              ) : (
                <span className="text-purple-200 font-medium">
                  Regenerando imagen... {progress}%. Podrás ver tu nuevo
                  resultado deslizando hacia abajo.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegenerationProgress;

// Regenerando imagen... {progress}%. Podrás ver tu nuevo
// resultado deslizando hacia abajo.
