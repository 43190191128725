import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../api/supabaseClient";
import { FaDownload, FaSync, FaArrowLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { API_URL } from "../config";
import RegenerationProgress from "../components/RegenerationProgress";

const translationMap = {
  "Dormitorio principal": "Bedroom",
  "Sala de estar": "Living room",
  "Dormitorio infantil": "Children's bedroom",
  Comedor: "Dining room",
  "Oficina en casa": "Home Office",

  // Style types
  Normal: "Standard",
  Moderno: "Modern",
  Escandinavo: "Scandinavian",
  Industrial: "Industrial",
  Lujo: "Luxury",
  Costero: "Coastal",
};

const translateToEnglish = (value) => {
  if (!value) return value;
  return translationMap[value] || value;
};

const ShowResult = () => {
  const { t } = useTranslation();
  const [originalImage, setOriginalImage] = useState(null);
  const [stagedImages, setStagedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [regenerationsLeft, setRegenerationsLeft] = useState(null);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [regenerationProgress, setRegenerationProgress] = useState(0);
  const [regenerationError, setRegenerationError] = useState(null);

  const navigate = useNavigate();

  const fetchResults = useCallback(async () => {
    try {
      setLoading(true);
      const { data: imageData, error: imageError } = await supabase
        .from("images")
        .select("*")
        .eq("id", id)
        .single();

      if (imageError) throw imageError;

      setOriginalImage(imageData);

      setRegenerationsLeft(5 - (imageData.regeneration_count || 0));

      const { data: stagedData, error: stagedError } = await supabase
        .from("staged_images")
        .select("*")
        .eq("image_id", id);

      if (stagedError) throw stagedError;

      setStagedImages(stagedData);
    } catch (error) {
      setError("Failed to load results. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isRegenerating) {
        e.preventDefault();
        e.returnValue = ""; // This is required for Chrome
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isRegenerating]);

  const handleDownload = async (imageUrl, index) => {
    try {
      // Fetch the image
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Get the actual MIME type from the blob
      const mimeType = blob.type;

      // Get proper extension from MIME type
      const getExtensionFromMimeType = (mimeType) => {
        const mimeToExt = {
          "image/jpeg": "jpg",
          "image/jpg": "jpg",
          "image/png": "png",
          "image/webp": "webp",
          "image/gif": "gif",
        };
        return mimeToExt[mimeType] || "jpg";
      };

      const extension = getExtensionFromMimeType(mimeType);

      // Generate the appropriate filename
      const fileName =
        index === "original"
          ? `original.${extension}`
          : `home_staged_${index + 1}.${extension}`;

      // Create download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      alert("Failed to download image. Please try again.");
    }
  };

  const handleRegenerateClick = () => {
    setShowRegenerateModal(true);
  };

  const confirmRegenerate = () => {
    setShowRegenerateModal(false);
    handleRegenerate();
    // You'll implement the actual regeneration count logic later
    setRegenerationsLeft((prev) => Math.max(0, prev - 1));
  };

  const handleRegenerate = async () => {
    try {
      setShowRegenerateModal(false);
      setIsRegenerating(true);
      setRegenerationError(null);
      setRegenerationProgress(10);

      // Get the original image details
      const { data: imageData, error: imageError } = await supabase
        .from("images")
        .select("*")
        .eq("id", id)
        .single();

      if (imageError) {
        throw new Error("Failed to fetch image data");
      }

      // Check if we've reached the regeneration limit
      if ((imageData.regeneration_count || 0) >= 5) {
        throw new Error("Maximum regenerations reached");
      }

      const response = await fetch(
        `${API_URL}/api/v1/regenerate-image`.trim(),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            image_id: id,
            staging_type: imageData.staging_type,
            room_type: translateToEnglish(imageData.room_type),
            style_type: translateToEnglish(imageData.style_type),
          }),
          credentials: "include",
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.detail || "Failed to start regeneration");
      }

      // Update the regenerationsLeft count
      setRegenerationsLeft((prev) => prev - 1);

      pollRegenerationStatus(responseData.prediction_id);
    } catch (error) {
      setRegenerationError(error.message);
      setIsRegenerating(false);
    }
  };

  const pollRegenerationStatus = async (predictionId) => {
    const pollInterval = setInterval(async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/v1/check-prediction/${predictionId}`.trim(),
          { credentials: "include" }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.detail || "Failed to check status");
        }

        // Update progress based on status
        setRegenerationProgress(calculateProgress(data.status));

        // Check for both 'succeeded' and 'completed' status
        if (data.status === "succeeded") {
          clearInterval(pollInterval);
          await fetchResults(); // Refresh all results
          setIsRegenerating(false);
        } else if (data.status === "failed") {
          clearInterval(pollInterval);
          setRegenerationError("Regeneration failed");
          setIsRegenerating(false);
        }
      } catch (error) {
        console.error("Polling error:", error); // Add this for debugging
        setRegenerationError(`Status check error: ${error.message}`);
      }
    }, 5000);

    // Cleanup function
    return () => {
      clearInterval(pollInterval);
    };
  };

  // Add this helper function
  const calculateProgress = (status) => {
    switch (status) {
      case "starting":
        return 10;
      case "processing":
        return 50;
      case "succeeded":
        return 100;
      case "failed":
        return 0;
      case "initializing": // Add this new state
        return 0;
      default:
        return 25; // Changed from 25 to 0
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-red-500 mb-4">{error}</p>
        <button
          onClick={() => navigate("/dashboard")}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("app.pages.Results.buttons.backToDashboard")}
        </button>
      </div>
    );
  }

  if (!originalImage || stagedImages.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-yellow-500 mb-4">
          {t("app.pages.Results.loading.noResults")}
        </p>
        <button
          onClick={fetchResults}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
        >
          {t("app.pages.Results.buttons.refreshResults")}
        </button>
        <button
          onClick={() => navigate("/dashboard")}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("app.pages.Results.buttons.backToDashboard")}
        </button>
      </div>
    );
  }
  const RegenerationCard = () => (
    <div className="relative rounded-lg overflow-hidden shadow-md bg-gray-50 h-[300px]">
      <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center p-4">
        <div className="animate-spin rounded-full h-10 w-10 border-4 border-white border-t-transparent mb-4" />
        <p className="text-white text-center font-medium mb-2">
          {t("app.pages.Results.regeneration.generating")}
        </p>
        <div className="w-full max-w-xs bg-gray-700 rounded-full h-2 mb-4">
          <div
            className="bg-white h-2 rounded-full transition-all duration-300"
            style={{ width: `${regenerationProgress}%` }}
          />
        </div>
        <p className="text-white/80 text-sm text-center">
          {t("app.pages.Results.regeneration.timeEstimate")}
        </p>
      </div>
    </div>
  );

  const RegenerateModal = ({ show, onClose, onConfirm, regenerationsLeft }) => (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center ${
        show ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg p-6 max-w-md mx-4">
        <h3 className="text-lg font-semibold mb-4">
          {t("app.pages.Results.modal.title")}
        </h3>
        <p className="text-gray-600 mb-4">
          {t("app.pages.Results.modal.credits", { count: regenerationsLeft })}
        </p>
        {regenerationsLeft === 0 && (
          <p className="text-red-500 mb-4">
            {t("app.pages.Results.modal.maxReached")}
          </p>
        )}
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium rounded-lg"
          >
            {t("app.pages.Results.modal.cancel")}
          </button>
          <button
            onClick={onConfirm}
            disabled={regenerationsLeft === 0}
            className="px-4 py-2 bg-[#0198FE] hover:bg-[#0182d8] text-white font-medium rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {t("app.pages.Results.modal.confirm")}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="px-4 py-6 sm:px-0">
      {isRegenerating && (
        <RegenerationProgress
          progress={regenerationProgress}
          error={regenerationError}
        />
      )}
      <div className="mb-6 flex justify-between items-center">
        <button
          onClick={() => navigate("/dashboard")}
          className="flex items-center text-[#0198FE] hover:text-[#0182d8] transition-colors duration-300"
        >
          <FaArrowLeft className="mr-2" />{" "}
          {t("app.pages.Results.buttons.backToDashboard")}
        </button>

        <div className="flex items-center">
          <button
            onClick={handleRegenerateClick}
            disabled={isRegenerating || regenerationsLeft === 0}
            className={`flex items-center px-3 py-1.5 text-sm border border-gray-300 
              text-gray-700 hover:bg-gray-50 rounded-lg transition-colors duration-300 
              disabled:opacity-50 disabled:cursor-not-allowed ${
                isRegenerating ? "bg-gray-100" : ""
              }`}
          >
            <FaSync
              className={`mr-2 h-3 w-3 ${isRegenerating ? "animate-spin" : ""}`}
            />
            {isRegenerating
              ? t("app.pages.Results.buttons.regenerating")
              : t("app.pages.Results.buttons.regenerate")}
          </button>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="text-xl font-semibold text-gray-900 mb-6">
            {t("app.pages.Results.headings.stagingResults")}
          </h1>

          {/* Mobile Layout */}
          <div className="md:hidden space-y-6">
            {/* Original Image Section */}
            <div>
              <h2 className="text-lg font-medium text-gray-900 mb-3">
                {t("app.pages.Results.headings.originalImage")}
              </h2>
              <div className="rounded-lg overflow-hidden shadow-md">
                <img
                  src={originalImage.input_image_url}
                  alt={t("app.pages.Results.headings.originalImage")}
                  className="w-full h-auto"
                />
              </div>
              <button
                onClick={() =>
                  handleDownload(originalImage.input_image_url, "original")
                }
                className="mt-3 w-full flex items-center justify-center bg-[#0198FE] hover:bg-[#0182d8] text-white font-medium py-2.5 px-4 rounded-lg transition-colors duration-300"
              >
                <FaDownload className="mr-2" />{" "}
                {t("app.pages.Results.download.original")}{" "}
              </button>
            </div>

            {/* Staged Images Section */}
            <div className="space-y-6">
              <h2 className="text-lg font-medium text-gray-900 mb-3">
                {t("app.pages.Results.headings.stagedImages")}
              </h2>
              {stagedImages.map((stagedImage, index) => (
                <div key={stagedImage.id} className="space-y-3">
                  <div className="rounded-lg overflow-hidden shadow-md">
                    <img
                      src={stagedImage.staged_image_url}
                      alt={t("app.pages.Results.stagedImages.altText", {
                        number: index + 1,
                      })}
                      className="w-full h-auto"
                    />
                  </div>
                  <button
                    onClick={() =>
                      handleDownload(stagedImage.staged_image_url, index)
                    }
                    className="w-full flex items-center justify-center bg-[#0198FE] hover:bg-[#0182d8] text-white font-medium py-2.5 px-4 rounded-lg transition-colors duration-300"
                  >
                    <FaDownload className="mr-2" />{" "}
                    {t("app.pages.Results.download.staged", {
                      number: index + 1,
                    })}
                  </button>
                </div>
              ))}
              {isRegenerating && <RegenerationCard />}
              {regenerationError && (
                <div className="rounded-lg bg-red-50 p-4 text-red-800">
                  <p>{regenerationError}</p>
                  <button
                    onClick={() => setRegenerationError(null)}
                    className="text-sm text-red-600 hover:text-red-800"
                  >
                    {t("app.pages.Results.regeneration.dismiss")}
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Desktop Layout */}
          <div className="hidden md:grid md:grid-cols-2 gap-6">
            {/* Original Image */}
            <div>
              <h2 className="text-lg font-medium text-gray-900 mb-3">
                {t("app.pages.Results.headings.originalImage")}
              </h2>
              <div className="rounded-lg overflow-hidden shadow-md">
                <img
                  src={originalImage.input_image_url}
                  alt={t("app.pages.Results.headings.originalImage")}
                  className="w-full h-auto"
                />
              </div>
              <button
                onClick={() =>
                  handleDownload(originalImage.input_image_url, "original")
                }
                className="mt-3 w-full flex items-center justify-center bg-[#0198FE] hover:bg-[#0182d8] text-white font-medium py-2.5 px-4 rounded-lg transition-colors duration-300"
              >
                <FaDownload className="mr-2" />{" "}
                {t("app.pages.Results.download.original")}
              </button>
            </div>

            {/* Staged Images */}
            <div>
              <h2 className="text-lg font-medium text-gray-900 mb-3">
                {t("app.pages.Results.headings.stagedImages")}
              </h2>
              <div className="space-y-6">
                {stagedImages.map((stagedImage, index) => (
                  <div key={stagedImage.id} className="space-y-3">
                    <div className="rounded-lg overflow-hidden shadow-md">
                      <img
                        src={stagedImage.staged_image_url}
                        alt={t("app.pages.Results.stagedImages.altText", {
                          number: index + 1,
                        })}
                        className="w-full h-auto"
                      />
                    </div>
                    <button
                      onClick={() =>
                        handleDownload(stagedImage.staged_image_url, index)
                      }
                      className="w-full flex items-center justify-center bg-[#0198FE] hover:bg-[#0182d8] text-white font-medium py-2.5 px-4 rounded-lg transition-colors duration-300"
                    >
                      <FaDownload className="mr-2" />{" "}
                      {t("app.pages.Results.download.staged", {
                        number: index + 1,
                      })}
                    </button>
                  </div>
                ))}
                {isRegenerating && <RegenerationCard />}
                {regenerationError && (
                  <div className="rounded-lg bg-red-50 p-4 text-red-800">
                    <p>{regenerationError}</p>
                    <button
                      onClick={() => setRegenerationError(null)}
                      className="text-sm text-red-600 hover:text-red-800"
                    >
                      {t("app.pages.Results.regeneration.dismiss")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RegenerateModal
        show={showRegenerateModal}
        onClose={() => setShowRegenerateModal(false)}
        onConfirm={confirmRegenerate}
        regenerationsLeft={regenerationsLeft}
      />
    </div>
  );
};

export default ShowResult;
